import * as Yup from "yup";


export const addCardSchema = Yup.object().shape({
    name: Yup.string()
    .min(2, "First Name is Too Short! it must be between 2 to 50 characters")
    .max(50, "First Name is Too Long! it must be between 2 to 50 characters")
    .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Name Required"),
    // cardNumber: Yup.mixed().required(),
    // cardExpiry: Yup.mixed().required(),
    // cardCvc: Yup.mixed().required(),
    cardNumber: Yup.boolean().oneOf([true], "Card info required"),
    cardExpiry: Yup.boolean().oneOf([true], "Card info required"),
    cardCvc: Yup.boolean().oneOf([true], "Card info required"),
  })