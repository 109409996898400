import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const { TextArea } = Input;
const StyledInput = styled(TextArea).attrs({className: 'inputField'})`
& {
    /* background-color: ${props => props.theme[props.theme.mode].background.secondary}; */
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    /* border: 1px solid ${props => props.theme[props.theme.mode].primary.main}66; */
    /* border-radius: 5px; */
    border: none;
    //box-shadow: 0 4px 14px -5px rgba(0,0,0,0.15);
    height: auto;
    border: 1px solid ${props => props.theme[props.theme.mode].primary.main}66;
    border-radius: 5px;
    // font-style: italic;
    /* border-bottom: 2px solid ${props => props.theme[props.theme.mode].primary.main}66; */
}
&:hover {
  /* border: 1px solid ${props => props.theme[props.theme.mode].primary.main}; */
  /* border-color: ${props => props.theme[props.theme.mode].primary.main} !important; */
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  //box-shadow: none;
  //box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66;
}
&:focus {
  /* border: 1px solid ${props => props.theme[props.theme.mode].primary.main}; */
  /* border-color: ${props => props.theme[props.theme.mode].primary.main} !important; */
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  border-bottom: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  /* box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66; */
  //box-shadow: none;
  box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66;
}
* {
  color: ${props => props.theme[props.theme.mode].primary.main};
}
&.lightBg {
    background-color: rgba(255, 255, 255, 0.5);
}
`;

const TextAreaWrapper = props => {
  return <StyledInput {...props}></StyledInput>;
};

export default TextAreaWrapper;
