import {
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_ERROR,
  DELETE_CONTACT_REQUEST,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_ERROR,
  EDIT_CONTACT_REQUEST,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  loading: false,
  userContacts: [],
  tableLoading: false,
  count: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CONTACT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case ADD_CONTACT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }

    case ADD_CONTACT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case GET_CONTACTS_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case GET_CONTACTS_SUCCESS: {
      let contactsHolder = action.contactsResults.user_contacts;
      console.log("contactsHolder", contactsHolder);

      if (
        state.userContacts.length !== 0 &&
        !action.clearFlag &&
        !action.ofPage
      ) {
        contactsHolder = [...state.userContacts, ...contactsHolder];
        console.log("setcontactsHolder array", contactsHolder);
      }

      if (action.ofPage) {
        console.log("pahge ofSize", action.ofPage);
        let { page, pageSize } = action.ofPage;

        let newUserContacts = [...state.userContacts];
        console.log("new newUserContacts", newUserContacts);
        newUserContacts.splice(
          page * pageSize,
          newUserContacts.length - page * pageSize,
          ...contactsHolder
        );
        contactsHolder = newUserContacts;
      }

      return Object.assign({}, state, {
        loading: false,
        count: action.contactsResults.count,
        userContacts: contactsHolder,
      });
    }

    case GET_CONTACTS_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    // -- Delete CONATC Reducers

    case DELETE_CONTACT_REQUEST: {
      console.log("D - Req Run");
      return Object.assign({}, state, { tableLoading: true });
    }

    case DELETE_CONTACT_SUCCESS: {
      console.log("D - Success Run");
      return Object.assign({}, state, { tableLoading: false });
    }

    case DELETE_CONTACT_ERROR: {
      console.log("D - Error Run");
      return Object.assign({}, state, { tableLoading: false });
    }

    case EDIT_CONTACT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }

    case EDIT_CONTACT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }

    case EDIT_CONTACT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    default: {
      return state;
    }
  }
};
