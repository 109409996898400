import {
  ADD_BANK_ACCOUNT_REQUEST,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_ERROR,
  GET_BANK_ACCOUNTS_ERROR,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_REQUEST,
  DELETE_BANK_ACCOUNT_REQUEST,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_ERROR,
  UPDATE_BANK_ACCOUNT_REQUEST,
  UPDATE_BANK_ACCOUNT_SUCCESS,
  UPDATE_BANK_ACCOUNT_ERROR,
  GET_ONE_BANK_ERROR,
  GET_ONE_BANK_SUCCESS,
  GET_ONE_BANK_REQUEST,
  CLEAR_ONE_BANK,
  RECORD_ENTRY
} from "../configurations/Types";

const INITIAL_STATE = {
  loading: false,
  allLoading: false,
  bankStatsLoading: false,
  bankAccountsLoading: false,
  totalBalance: 0,
  totalCleared: 0,
  currency: "$",
  totalCount: 0,
  bankAccounts: [],
  bankLists: null,
  // total_count: 0,
  singleLoading: false,
  bankDetail: null,

  lastLawFirmName: "",
  lastBankAccountName: "",
  lastBankName: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // ADD NEW BANK
    case ADD_BANK_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case ADD_BANK_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case ADD_BANK_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    // GET ALL BANKS
    case GET_BANK_ACCOUNTS_REQUEST: {
      return Object.assign({}, state, {
        allLoading: true,
        bankStatsLoading: !(state.totalBalance || state.totalCleared),
        bankAccountsLoading: true,
        bankAccounts: action.flag ? [] : state.bankAccounts,
        totalCount: action.flag ? 0 : state.totalCount
        // bankLists:
        //   state.bankLists && action.flag
        //     ? { ...state.bankLists, bank_accounts: [], total_count: 0 }
        //     : state.bankLists
      });
    }
    case GET_BANK_ACCOUNTS_SUCCESS: {
      let totalBalance = 0;
      let totalCleared = 0;
      let newCurrency = state.currency;
      let totalCount = 0;
      let bankAccounts = [];

      if (action.payload) {
        let {
          total_balance,
          total_cleared_balance,
          currency,
          total_count,
          bank_accounts
        } = action.payload;

        //console.log("REDUCER SUCCESS", action.payload, total_balance);

        totalBalance = total_balance;
        totalCleared = total_cleared_balance;
        newCurrency = currency;
        totalCount = total_count;
        bankAccounts = [...state.bankAccounts, ...bank_accounts];
      }

      return Object.assign({}, state, {
        allLoading: false,
        // bankLists: action.payload,

        bankStatsLoading: false,
        bankAccountsLoading: false,

        totalBalance,
        totalCleared,
        currency: newCurrency,
        totalCount,
        bankAccounts

        // bankLists: state.bankLists
        //   ? {
        //       ...state.bankLists,
        //       bank_accounts: [
        //         ...state.bankLists.bank_accounts,
        //         ...action.payload.bank_accounts
        //       ]
        //     }
        //   : action.payload
      });
    }
    case GET_BANK_ACCOUNTS_ERROR: {
      return Object.assign({}, state, {
        allLoading: false,
        bankStatsLoading: false,
        bankAccountsLoading: false
      });
    }

    // DELETE A BANK
    case DELETE_BANK_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { allLoading: true });
    }
    case DELETE_BANK_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { allLoading: false });
    }
    case DELETE_BANK_ACCOUNT_ERROR: {
      return Object.assign({}, state, { allLoading: false });
    }

    // Get Single bank
    case GET_ONE_BANK_REQUEST: {
      return Object.assign({}, state, { singleLoading: true });
    }

    case GET_ONE_BANK_SUCCESS: {
      return Object.assign({}, state, {
        singleLoading: false,
        bankDetail: action.payload
      });
    }
    case GET_ONE_BANK_ERROR: {
      return Object.assign({}, state, { singleLoading: false });
    }
    case CLEAR_ONE_BANK: {
      return Object.assign({}, state, { bankDetail: null });
    }

    // Update  BANK
    case UPDATE_BANK_ACCOUNT_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case UPDATE_BANK_ACCOUNT_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case UPDATE_BANK_ACCOUNT_ERROR: {
      return Object.assign({}, state, { loading: false });
    }

    case RECORD_ENTRY: {
      return Object.assign({}, state, { 
        lastLawFirmName: action.lawFirmName,
        lastBankAccountName: action.bankAccountName,
        lastBankName: action.bankName
       });
    }

    default: {
      return state;
    }
  }
};
