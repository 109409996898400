import {
  GET_BANK_TRANSACTION_REQUEST,
  GET_BANK_TRANSACTION_SUCCESS,
  GET_BANK_TRANSACTION_ERROR,
  BANK_ACCOUNT_SUMMARY_REQUEST,
  BANK_ACCOUNT_SUMMARY_SUCCESS,
  BANK_ACCOUNT_SUMMARY_ERROR,
  GET_RECURRING_REQUEST,
  GET_RECURRING_SUCCESS,
  GET_RECURRING_ERROR
} from "../configurations/Types";

export const getBankTransactionsRequest = (params, cb, clearFlag, ofPage) => {
  return {
    type: GET_BANK_TRANSACTION_REQUEST,
    params,
    callback: cb,
    clearFlag,
    ofPage
  };
};
export const getBankTransactionsSuccess = (bankDetails, clearFlag, ofPage) => {
  console.log("bank Details succccccccc", bankDetails);
  return {
    type: GET_BANK_TRANSACTION_SUCCESS,
    bankDetails,
    clearFlag,
    ofPage
  };
};
export const getBankTransactionsError = error => {
  return {
    type: GET_BANK_TRANSACTION_ERROR,
    error
  };
};

export const getBankAccountSummaryRequest = (id, month, year) => {
  return {
    type: BANK_ACCOUNT_SUMMARY_REQUEST,
    id,
    month,
    year
  };
};

export const getBankAccountSummarySuccess = payload => {
  console.log("getBankAccountSummarySuccess", payload);
  return {
    type: BANK_ACCOUNT_SUMMARY_SUCCESS,
    payload
  };
};
export const getBankAccountSummaryError = error => {
  return {
    type: BANK_ACCOUNT_SUMMARY_ERROR,
    error
  };
};

export const getRecurringRequest = (params, cb, clearFlag, ofPage) => {
  console.log("action params recuurings",params)
  return {
    type: GET_RECURRING_REQUEST,
    params,
    callback: cb,
    clearFlag,
    ofPage
  };
};

export const getRecurringSuccess = (recurringData, clearFlag, ofPage) => {
  console.log("getRecurringSuccess",recurringData)
  return {
    type: GET_RECURRING_SUCCESS,
    recurringData,
    clearFlag,
    ofPage
  };
};

export const getRecurringError = error => {
  return {
    type: GET_RECURRING_ERROR,
    error
  };
};
