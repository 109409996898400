import * as Yup from "yup";

export const addContactSchema = Yup.object().shape({
  full_name: Yup.string()
    //.matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Full Name Required"),

  company_name: Yup.string()
  // .matches(
  //   /^([A-Za-z0-9 _.,!"'-]*)$/i,
  //   "No special characters allowed"
  // )
  ,

  phone: Yup.string().matches(
    /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
    "Please provide a valid Phone Number"
  ),

  email: Yup.string()
    .email("Email is not valid!")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    ),
  // .required("Email Required"),

  address: Yup.string(),
  // .min(1, "Provide adress b/w 20 to 200 charactres")
  // .max(200, "Provide adress b/w 20 to 200 charactres"),

  comment: Yup.string().nullable(),

  contact_type: Yup.string().nullable().required("Contact Type Required"),
});
