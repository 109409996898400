import {
  GET_PRINT_TRANSACTION_REQUEST,
  GET_PRINT_TRANSACTION_SUCCESS,
  GET_PRINT_TRANSACTION_ERROR,
  GET_CREATE_CHECK_REQUEST,
  GET_CREATE_CHECK_SUCCESS,
  GET_CREATE_CHECK_ERROR,
} from "../configurations/Types";

export function getPrintChecksTransactionsRequest(
  params,
  cb,
  clearFlag,
  ofPage
) {
  console.log("SERACH_TRANSACTIONS_REQUEST action", params);
  return {
    type: GET_PRINT_TRANSACTION_REQUEST,
    params,
    callback: cb,
    clearFlag,
    ofPage,
  };
}
export function getPrintChecksTransactionsSuccess(
  transactionData,
  clearFlag,
  ofPage
) {
  console.log("searchTransactionsSuccess", transactionData);
  return {
    type: GET_PRINT_TRANSACTION_SUCCESS,
    payload: transactionData,
    clearFlag,
    ofPage,
  };
}
export function getPrintChecksTransactionsError(error) {
  return {
    type: GET_PRINT_TRANSACTION_ERROR,
    error,
  };
}

export const getCreateCheckRequest = (
  printDetails,
  checkCount,
  lastCheckNumber,
  cb
) => {
  return {
    type: GET_CREATE_CHECK_REQUEST,
    printDetails,
    checkCount,
    lastCheckNumber,
    cb,
  };
};

export const getCreateCheckSucces = (pdfUrl) => {
  return {
    type: GET_CREATE_CHECK_SUCCESS,
    pdfUrl,
  };
};

export const getCreateCheckError = (error) => {
  return {
    type: GET_CREATE_CHECK_ERROR,
    error,
  };
};
