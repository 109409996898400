import {
  GET_CHARTS_ERROR,
  GET_CHARTS_SUCCESS,
  GET_CHARTS_REQUEST,
  CLEAR_CHARTS,
} from "../configurations/Types";

import moment from "moment";

const INITIAL_STATE = {
  chartLoad: false,
  totalAmount: null,
  totalIncome: null,
  totalExpense: null,
  currency: "",
  accountType: "",
  transactions: [],
  incomeTrans: [],
  expenseTrans: [],
  transTotals: [],
  totalsByDate: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CHARTS_REQUEST: {
      return Object.assign({}, state, { chartLoad: true });
    }
    case GET_CHARTS_SUCCESS:
      let transIncome = [],
        transExpense = [],
        totals = [],
        dateTotals = [];
      if (action.payload) {
        const {
          total_amount,
          currency,
          account_type,
          transaction,
          total_income,
          total_expense,
          // date_type,
        } = action.payload;
        let { date_type } = action;
        // const [income, expense] = // Acha Tareeqa hai but abhi nhi! // Use "deconstruction" style assignment
        // transaction
        //   .reduce((result, element) => {
        //     result[element.transaction_type.toLowerCase() === "income" ? 0 : 1].push(element); // Determine and push to small/large arr
        //     return result;
        //   },
        //   [[], []]);
        // ---- Extract Income and Expense types on to a new array ----
        if (account_type === "both") {
          transIncome = transaction.filter(
            (val) => val.transaction_type.toLowerCase() === "income"
          );
          transExpense = transaction.filter(
            (val) => val.transaction_type.toLowerCase() === "expense"
          );

          let byDate = {};

          totals = transaction.reduce(
            (total, curr) => {
              let dateString;
              if (date_type === "monthly") {
                let weekOfMonth =
                  moment(curr.create_date).week() -
                  moment(curr.create_date)
                    .subtract("days", moment(curr.create_date).date() - 1)
                    .week() +
                  1;
                dateString = moment(curr.create_date).format("MMM, YYYY");

                dateString = "Week " + weekOfMonth;
                //+ " " + dateString;
              } else if (date_type === "yearly") {
                dateString = moment(curr.create_date).format("MMM");
              } else if (date_type === "quaterly") {
                dateString = moment(curr.create_date).format("MMM");
              } else if (date_type === "custom") {
                //Either this or same as monthly above
                dateString = moment(curr.create_date).format("MMM");
              }

              if (curr.transaction_type.toLowerCase() === "income") {
                total[0].value = total[0].value + curr.value;
                total[0].label = curr.label;

                if (byDate[dateString]) {
                  byDate[dateString].income =
                    byDate[dateString].income + curr.value;
                } else {
                  byDate[dateString] = {
                    income: curr.value,
                    expense: 0,
                    label: curr.label,
                  };
                }
              } else {
                total[1].value = total[1].value + curr.value;
                total[1].label = curr.label;

                if (byDate[dateString]) {
                  byDate[dateString].expense =
                    byDate[dateString].expense + curr.value;
                } else {
                  byDate[dateString] = {
                    expense: curr.value,
                    income: 0,
                    label: curr.label,
                  };
                }
              }
              return total;
            },
            [
              {
                a: null,
                value: 0,
                label: "",
                d: null,
                e: null,
                account_name: "income",
              },
              {
                a: null,
                value: 0,
                label: "",
                d: null,
                e: null,
                account_name: "expense",
              },
            ]
          );

          dateTotals = Object.entries(byDate).map((val) => ({
            name: val[0],
            income: val[1].income,
            expense: val[1].expense,
            label: val[1].label,
          }));
        }
        return Object.assign({}, state, {
          chartLoad: false,
          totalAmount: total_amount,
          totalIncome: total_income,
          totalExpense: total_expense,
          currency,
          accountType: account_type,
          transactions: transaction,
          incomeTrans: transIncome,
          expenseTrans: transExpense,
          transTotals: totals,
          totalsByDate: dateTotals,
        });
      }
    case GET_CHARTS_ERROR:
      return Object.assign({}, state, { chartLoad: false });
    case CLEAR_CHARTS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
