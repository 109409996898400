import {put, takeLatest } from "redux-saga/effects";
import { GET_CHARTS_REQUEST } from "../configurations/Types";
import {
  CHARTS_ENDPOINT,
  ACCOUNTS_CHARTS_ENDPOINT
} from "../configurations/Constants";
import {
  getChartsError,
  getChartsSuccess
} from "../actions/Charts";
// import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

const getChartsAPI = body => {
  return privateAgent.get(`${CHARTS_ENDPOINT}${ACCOUNTS_CHARTS_ENDPOINT}`, {params: body});
}

function* getCharts(action) {
  let res;
  try {
    console.log("Charts API: ", action.body)
    res = yield getChartsAPI(action.body);
    yield put(getChartsSuccess(res.data.data, action.body.date_type));
    console.log("Charts Response", res);
  } catch (e) {
    if (e.response) {
      try {
        info("error", `${e.response.data.error.message}`);
      } catch(e) {}
    }
    if (action.cb) {
      action.cb(e);
    }
    yield put(getChartsError());
  }
}

// Our watcher Saga: spawn a CHART on each GET_CHARTS_REQUEST
export function* watchCharts() {
  yield takeLatest(GET_CHARTS_REQUEST, getCharts);
}
