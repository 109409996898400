import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  GET_USERS_SETTINGS_REQUEST,
  UPDATE_USERS_SETTINGS_REQUEST,
} from "../configurations/Types";
import {
  USERS_SETTINGS_ENDPOINT,
  UPDATE_USER_SETTINGS_ENDPOINT,
} from "../configurations/Constants";

import {
  getUsersSettingSuccess,
  getUsersSettingsError,
  getUserSettingRequest,
  updateUsersSettingsError,
  updateUsersSettingsSuccess,
} from "../actions/Settings";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

// --get Settings
const getSettingsAPI = () => privateAgent.get(`${USERS_SETTINGS_ENDPOINT}`);
function* getSettings() {
  let res;
  try {
    res = yield getSettingsAPI();
    console.log(res, "getSettingsAPI");
    // info("success", `${res.data.data.message}`);
    yield put(getUsersSettingSuccess(res.data.data));
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (
      e &&
      e.response &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.message
    ) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(getUsersSettingsError());
  }
}
// --get Settings

// --Update Settings
// update bank account
const updateSettingApi = (body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  console.log("updateSettingApi", body);
  return privateAgent.put(
    `${USERS_SETTINGS_ENDPOINT}${UPDATE_USER_SETTINGS_ENDPOINT}`,
    {
      ...body,
      current_time,
    }
  );
};

function* updateSettings(action) {
  let res;
  try {
    res = yield updateSettingApi(action.body);
    // console.log("updateBankApi",res)

    yield put(getUserSettingRequest());
    yield put(updateUsersSettingsSuccess());
    info("success", `${res.data.data.message}`);
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(updateUsersSettingsError());
  }
}

// --Update Settings

// settings API requests
export function* watchSetings() {
  yield takeLatest(GET_USERS_SETTINGS_REQUEST, getSettings);
  yield takeLatest(UPDATE_USERS_SETTINGS_REQUEST, updateSettings);
}
