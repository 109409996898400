import * as Yup from "yup";

export const contactSchema = Yup.object().shape({
  name: Yup.string().required("Name Required"),
  email: Yup.string()
    .email("Invalid email!")
    .matches(/^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,"Email is not valid!")
    .required("Email Required!"),
     message: Yup.string()
    .max(500, "Limit exceeded!")
    .required("Message Required!")
});
