import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  ADD_CONTACT_REQUEST,
  GET_CONTACTS_REQUEST,
  DELETE_CONTACT_REQUEST,
  EDIT_CONTACT_REQUEST,
} from "../configurations/Types";
import {
  CREATE_CONTACT_ENDPOINT,
  CONTACT_USER_ENDPOINT,
  DELETE_CONTACT_ENDPOINT,
  EDIT_CONTACT_ENDPOINT,
} from "../configurations/Constants";
import {
  addAccountSuccess,
  addContactError,
  getUserContactsSuccess,
  getUserContactsError,
  deleteContactSuccess,
  deleteContactError,
  getUserContactsRequset,
} from "../actions/Contact";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";

const addContactApi = (body) => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  let { edit, ...res } = body;
  console.log("addContactApi", body);
  return privateAgent.post(`${CREATE_CONTACT_ENDPOINT}`, {
    ...res,
    current_time,
  });
};

function* addContactrequest(action) {
  console.log("action", action);
  let res;
  try {
    res = yield addContactApi(action.body);
    info("success", `${res.data.data.message}`);
    yield put(addAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(addContactError());
  }
}

let contactToken;
const getContactsAPI = (params, token) =>
  privateAgent.get(`${CONTACT_USER_ENDPOINT}`, {
    cancelToken: token.token,
    params: params,
  });
function* getUserContacts(action) {
  console.log("CONTACT SAGA RUN RECURRING", action);
  let res;

  try {
    if (contactToken) {
      contactToken.cancel();
    }
    contactToken = CancelToken.source();
    res = yield getContactsAPI(action.params, contactToken);
    console.log(res.data.data, "getContactsAPI");
    yield put(
      getUserContactsSuccess(res.data.data, action.clearFlag, action.ofPage)
    );
    // info("success", `${res.data.data.message}`);
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getUserContactsError(e));
  }
}

//-- Delete Contact
const deleteContactAPI = (id) => {
  console.log("deleteTransaction apicall", id);
  return privateAgent.delete(
    `${CONTACT_USER_ENDPOINT}${DELETE_CONTACT_ENDPOINT}/${id}`
  );
};

function* deleteContacts(action) {
  let res;
  try {
    res = yield deleteContactAPI(action.id);
    yield put(deleteContactSuccess());
    if (action.cb) {
      action.cb();
    }
    info("success", `${res.data.data.message}`);
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      // yield put(getUserContactsRequset());
      info("error", `${e.response.data.error.message}`);
    }
    yield put(deleteContactError(e));
  }
}
//-- Delete Contact

//--EDIT CONTACT
const editContactApi = (body) => {
  let { id, edit, ...properBody } = body;
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  console.log("addContactApi", body);
  return privateAgent.put(
    `${CONTACT_USER_ENDPOINT}${EDIT_CONTACT_ENDPOINT}/${body.id}`,
    {
      ...properBody,
      current_time,
    }
  );
};

function* editContacts(action) {
  console.log("action", action);
  let res;
  try {
    res = yield editContactApi(action.body);
    info("success", `${res.data.data.message}`);
    yield put(addAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(addContactError());
  }
}
//--EDIT CONTACT

// Our watcher Saga: spawn a Contacts All Requests
export function* watchContacts() {
  yield takeLatest(ADD_CONTACT_REQUEST, addContactrequest);
  yield takeLatest(GET_CONTACTS_REQUEST, getUserContacts);
  yield takeLatest(DELETE_CONTACT_REQUEST, deleteContacts);
  yield takeLatest(EDIT_CONTACT_REQUEST, editContacts);
}
