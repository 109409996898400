import React from "react";
import { Badge } from "antd";
import Styled from 'styled-components';

const BadgeWrap = Styled(Badge) `
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.btn};
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
  vertical-align: middle;
  & .ant-avatar i {
    font-size: inherit;
    margin: 25%;
  }
  & .ant-badge-dot {
    top: 10%;
    right: 10%;
    width: 0.5rem;
    height: 0.5rem;
  }
  & .ant-badge-count {
    height: 1.275rem;
  }
  & sup.ant-badge-count p {
    line-height: 1;
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.links};
    height: 1.275rem;
  }
`
const BadgeWrapper = props => {
  return <BadgeWrap {...props}></BadgeWrap>;
};

export default BadgeWrapper;
