import {
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR
} from "../configurations/Types";

export function profileUpdateRequest(body,cb) {
  return {
    type: PROFILE_UPDATE_REQUEST,
    body,
    cb
  };
}

export function profileUpdateSuccess(name) {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    payload: name
  };
}
  
export function profileUpdateError(error) {
    return {
      type: PROFILE_UPDATE_ERROR
    };

}
