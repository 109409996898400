import {
  RECORD_RECURRING_REQUEST,
  RECORD_RECURRING_SUCCESS,
  RECORD_RECURRING_ERROR,
  UPDATE_RECURRING_REQUEST,
  UPDATE_RECURRING_SUCCESS,
  UPDATE_RECURRING_ERROR,
} from "../../configurations/Types";

export const recordRecurringRequest = (body, cb) => {
  return {
    type: RECORD_RECURRING_REQUEST,
    body,
    cb
  };
};

export const recordRecurringSuccess = () => {
  return {
    type: RECORD_RECURRING_SUCCESS,
    
  };
};

export const recordRecurringError = () => {
  return {
    type: RECORD_RECURRING_ERROR
  };
};



//Edit recurring
export const updateRecurringRequest = (body, id, cb) => {
  return {
    type: UPDATE_RECURRING_REQUEST,
    body,
    id,
    cb
  };
};

export const updateRecurringSuccess = () => {
  return {
    type: UPDATE_RECURRING_SUCCESS,
    
  };
};

export const updateRecurringError = () => {
  return {
    type: UPDATE_RECURRING_ERROR
  };
};
