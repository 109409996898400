import * as Yup from "yup";

export const addBankSchema = Yup.object().shape({
  law_firm_name: Yup.string()
    // .min(
    //   2,
    //   "Law  Firm Name is Too Short! it must be between 2 to 50 characters"
    // )
    // .max(
    //   50,
    //   "Law  Firm Name is Too Long! it must be between 2 to 50 characters"
    // )
    //.matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide law firm name"),

  bank_account_name: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    //.matches(/^([A-Za-z0-9 _.,!"'-]*)$/i, "No special characters allowed")
    .required("Please provide your bank account name"),

  bank_name: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    //.matches(/^([A-Za-z0-9 _.,!"'-]*[^\s]$)$/i, "No special characters allowed")
    .matches(/^([A-Za-z ]*)$/i, "Only alphabets allowed")
    .required("Please provide your bank account name"),

  balance: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
      "Please provide a valid amount"
    ),
  // .required("Please provide your bank balance")
  open_date: Yup.string().required("Please provide a date"),

  additional_note: Yup.string()
    .min(0, "Optional- 500 Characters with spaces allowed")
    .max(500, "Optional- 500 Characters with spaces allowed"),
});
