import * as Yup from "yup";

export const buySubscriptionSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Please provide a valid email"
    )
    .required("Please provide an email"),

  cardNumber: Yup.number()
  .test("is-not-empty", "Please provide a card number", function(value) {
    return value !== 0
  })
  .test("is-valid", "Please provide a valid card number", function(value) {
    return value !== -1
  }),
  expiryDate: Yup.number()
  .test("is-not-empty", "Please provide an expiration date", function(value) {
    return value !== 0
  })
  .test("is-valid", "Please provide a valid expiration date", function(value) {
    return value !== -1
  }),
  cvc: Yup.number()
  .test("is-not-empty", "Please provide a cvc number", function(value) {
    return value !== 0
  })
  .test("is-valid", "Please provide a valid cvc number", function(value) {
    return value !== -1
  }),

  name: Yup.string()
    .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide a name"),

  //country: Yup.string().required("Please provide a country").nullable(),
});
