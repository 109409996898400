import * as Yup from "yup";

export const split = Yup.object().shape({
    amount:  Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(/(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/, "Please provide a valid amount")
    .required("Please provide an amount"),
    account_head_id: Yup.string()
      // .min(1, "Password is Too Short! it must be between 6 and 16 characters")
      // .max(16, "Password is Too Long! it must be between 6 and 16 characters")
      .required("Please provide your account name"),
    job_id: Yup.string()
    // .min(6, "Password is Too Short! it must be between 6 and 16 characters")
    //   .max(16, "Password is Too Long! it must be between 6 and 16 characters")
      .required("Please provide your job name"),
  });