import * as Yup from "yup";
import { split } from "./split";

export const transferWithSplits = Yup.object().shape({
  bankName: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your bank account name"),

  fromLawyerName: Yup.string()
    // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
    // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide sender lawyer name"),

  fromCustomerName: Yup.string()
    // .min(
    //   2,
    //   "customerName   is Too Short! it must be between 2 to 50 characters"
    // )
    // .max(
    //   50,
    //   "customerName   is Too Long! it must be between 2 to 50 characters"
    // )
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide sender customer name"),

  

  fromAccountName: Yup.string()
  // .min(
  //   2,
  //   "customerName   is Too Short! it must be between 2 to 50 characters"
  // )
  // .max(
  //   50,
  //   "customerName   is Too Long! it must be between 2 to 50 characters"
  // )
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide sender account name"),

  fromJobName: Yup.string()
    // .min(2, "jobName is Too Short! it must be between 2 to 50 characters")
    // .max(50, "jobName is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your job name")
    ,

  toLawyerName: Yup.string()
  // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide receiver lawyer name"),

  toCustomerName: Yup.string()
    // .min(
    //   2,
    //   "customerName   is Too Short! it must be between 2 to 50 characters"
    // )
    // .max(
    //   50,
    //   "customerName   is Too Long! it must be between 2 to 50 characters"
    // )
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    .required("Please provide your receiver customer name"),

  fromAccountType: Yup.string(),

  remainingAmount: Yup.number().when("splits", (splits, schema) => splits && splits.length > 1 ? schema.oneOf(
    [0],
    "Splits do not equal the total amount"
  ) : schema),

  is_split: Yup.boolean(),
  noOfSplits: Yup.number().moreThan(1, "Please add at least two splits"),
  splits: Yup.array(split),

  amount: Yup.string()
    // .typeError("Please provide a valid amount")
    //.min(1, "Please input a valid balance")
    .matches(
      /(^\d*\.?\d*[0-9]+\d*$)|(^[0-9]+\d*\.\d*$)/,
      "Please provide a valid amount"
    )
    .required("Please provide an amount")
    .notOneOf(["0"], "Please provide an amount"),

  dateOfTransaction: Yup.date().required("Please provide transaction date").nullable(),
  //.required("Date of Transaction Required"),

  // checkNumber: Yup.number().typeError("Check number must be a number")
  // //.required("Check Number is Required")
  // ,

  // cleared: Yup.string()
  // //.required("Cleared is Required")
  // ,

  additionalNote: Yup.string(),

  // image: Yup.string()
});
