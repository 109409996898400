import {
  GET_USERS_SETTINGS_ERROR,
  GET_USERS_SETTINGS_SUCCESS,
  GET_USERS_SETTINGS_REQUEST,
  UPDATE_USERS_SETTINGS_REQUEST,
  UPDATE_USERS_SETTINGS_SUCCESS,
  UPDATE_USERS_SETTINGS_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  loading: false,
  settings: {},
  empty: true,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SETTINGS_REQUEST:
      console.log("GET_USERS_SETTINGS_REQUEST RED");
      return Object.assign({}, state, { loading: true });

    case GET_USERS_SETTINGS_SUCCESS:
      console.log("GET_USERS_SETTINGS_SUCCESS", action);
      return Object.assign({}, state, {
        loading: false,
        settings: action.body,
        empty: false,

      });

    case GET_USERS_SETTINGS_ERROR:
      console.log("GET_USERS_SETTINGS_ERROR");
      return Object.assign({}, state, { loading: false });

    case UPDATE_USERS_SETTINGS_REQUEST:
      console.log("UPDATE_USERS_SETTINGS_REQUEST RED");
      return Object.assign({}, state);

    case UPDATE_USERS_SETTINGS_SUCCESS:
      console.log("UPDATE_USERS_SETTINGS_REQUEST");
      return Object.assign({}, state, {
        // loading: false
      });

    case UPDATE_USERS_SETTINGS_ERROR:
      console.log("GET_USERS_SETTINGS_ERROR");
      return Object.assign({}, state);

    default: {
      return state;
    }
  }
};
