import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  GET_PRINT_TRANSACTION_REQUEST,
  GET_CREATE_CHECK_REQUEST,
} from "../configurations/Types";
import {
  BANK_ACCOUNT_ENDPOINT,
  PRINT_CHECQUE_TRANSACTION_ENDPOINT,
  CREATE_CHECK_ENDPOINT,
} from "../configurations/Constants";

import {
  getPrintChecksTransactionsSuccess,
  getPrintChecksTransactionsError,
  getCreateCheckError,
  getCreateCheckSucces,
} from "../actions/PrintChecks";

import moment from "moment";
import info from "../components/message/index";

import {
  privateAgent,
  CancelToken,
  //   instaAgent
} from "../configurations/AxiosAgent";

// Print Transaction Saga

let token;

const printTransactionsAPI = (params, token) => {
  let { bankId, ...rest } = params;
  return privateAgent.get(
    `${BANK_ACCOUNT_ENDPOINT}/${bankId.bankId}${PRINT_CHECQUE_TRANSACTION_ENDPOINT}`,
    {
      cancelToken: token.token,
      params: rest,
    }
  );
};

function* getPrintTransaction(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield printTransactionsAPI(action.params, token);
    console.log("USER API GET", res.data);
    yield put(
      getPrintChecksTransactionsSuccess(
        res.data.data,
        action.clearFlag,
        action.ofPage
      )
    );
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getPrintChecksTransactionsError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

// create check
const createCheckApi = (data, checkCount, lastCheckNumber) => {
  // let arr = [];

  // for (let i = 0; i < data.length; i++) {
  //   data[i].id =data[i]["transaction_source_id"];
  //   data[i].date = moment(data[i]["date_of_transaction"]).format("MM-DD-YYYY");
  //   data[i].address = data[i]["contact_address"];
  //   data[i].amount = Number(data[i]["amount"]);

  //   delete data[i].transaction_source_id;
  //   delete data[i].date_of_transaction;
  //   delete data[i].contact_address;
  //   delete data[i].currency;
  //   delete data[i].job_name;
  //   delete data[i].account_name;
  //   delete data[i].transaction_type;
  //   delete data[i].is_split;
  //   delete data[i].is_recurring;
  // }

  // data.map((item) => {
  //   console.log(item, "item");
  //   // let {item.currency,...item}=item
  //   let { currency, ...res } = item;
  //   console.log(res, "item");
  // });
  return privateAgent.get(`${BANK_ACCOUNT_ENDPOINT}${CREATE_CHECK_ENDPOINT}`, {
    params: {
      cheque_count: checkCount,
      data: JSON.stringify(data),
      last_check_number: lastCheckNumber,
    },
  });
  // console.log("createCheckApi", data);
};

function* getCreateCheck(action) {
  let res;

  try {
    let printDetails = action.printDetails.map((data) => ({
      id: data["transaction_source_id"],
      date: moment(data["date_of_transaction"]).format("MM-DD-YYYY"),
      address: data["contact_address"],
      amount: Number(data["amount"]),
      payor_name: data["payor_name"],
    }));
    res = yield createCheckApi(
      printDetails,
      action.checkCount,
      action.lastCheckNumber
    );
    yield put(getCreateCheckSucces(res.data.data));
    console.log("yield",res)
    if (action.cb) {
      action.cb(null, res.data.data);
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
    yield put(getCreateCheckError());
  }
}

// Our watcher Saga: spawn a new Search Transactions task on each USERS_REQUEST
export function* watchPrintTransactions() {
  yield takeLatest(GET_PRINT_TRANSACTION_REQUEST, getPrintTransaction);
  yield takeLatest(GET_CREATE_CHECK_REQUEST, getCreateCheck);
}
