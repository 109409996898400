import { useState, useEffect } from "react";


function useScrollToBottom() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScrollToBottom() {
      if ((window.innerHeight + window.pageYOffset) >= document.getElementById("root").clientHeight - 5) {
          setScrolled(!scrolled)
       }
    }

    window.addEventListener("scroll", handleScrollToBottom);
    return () => {
      window.removeEventListener("scroll", handleScrollToBottom);
    };
  });

  return scrolled;
}

export default useScrollToBottom;