import React from "react";
import { message } from 'antd';

const VerifyComponent = (props) => {
  return (
    // <React.Fragment>
    // <BrowserRouter>
      <p style={{ display: "inline" }}>
        Your account has not been verified yet, click <a onClick={() => 
          {
            props.verifyRedirect(props.email);
            message.destroy();
          }}>
              Here</a> to verify!
      </p>
    // </BrowserRouter>
    // </React.Fragment>
  )
}

// class VerifyComponent extends React.Component {
//   render() {
    
//   }
// }

// const VerifyComponentWithRouter = () => {
//   return (
//     <Route path="*" component={VerifyComponent} />
//   )
// };

// const verifyAccountMessage = () => {
//   info("warning", <VerifyComponent />, 0);
// }

export default VerifyComponent;