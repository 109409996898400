import * as Yup from "yup";

export const updateProfileSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(2, "Full Name is Too Short! it must be between 2 to 50 characters")
    .max(50, "Full Name is Too Long! it must be between 2 to 50 characters")
    .matches(/^[a-z0-9.\s]+$/i, "Only alphabets digits and periods are allowed")
    .required("Full Name Required"),
  email: Yup.string()
    .email("Email is not valid!")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email Required")
});
