import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { withTheme } from "styled-components"


const BackButton = props => {
  return (<div
    onClick={props.action}
    style={{
      display: "inline-flex",
      alignItems: "center",
      cursor: "pointer"
    }}
  >
    {/* <ButtonWrapper> */}
    <FiArrowLeft
      className="button"
      style={{
        marginRight: 10,
        //color: props.theme[props.theme.mode].primary.main,
        fontSize: "1.4rem"
      }}
    />
    {/* </ButtonWrapper> */}
    <p style={{fontWeight: 600, margin: 0,color:"#1E1E22"}}>
    {props.title}
    </p>
  </div>)
}

export default withTheme(BackButton);