import {
  DELETE_ACCOUNT_HEAD_REQUEST,
  DELETE_ACCOUNT_HEAD_SUCCESS,
  DELETE_ACCOUNT_HEAD_ERROR,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_ERROR,
} from "../../configurations/Types";

export const deleteAccountHeadRequest = (id, cb) => {
  return {
    type: DELETE_ACCOUNT_HEAD_REQUEST,
    id, 
    cb
  }
}
export const deleteAccountHeadSuccess = (payload) => {
  return {
    type: DELETE_ACCOUNT_HEAD_SUCCESS,
  }
}
export const deleteAccountHeadError = (e) => {
  return {
    type: DELETE_ACCOUNT_HEAD_ERROR,
    e
  }
}

export const deleteJobRequest = (id, cb) => {
  return {
    type: DELETE_JOB_REQUEST,
    id, 
    cb
  }
}
export const deleteJobSuccess = (payload) => {
  return {
    type: DELETE_JOB_SUCCESS,
  }
}
export const deleteJobError = (e) => {
  return {
    type: DELETE_JOB_ERROR,
    e
  }
}
