import {
  GET_TRANSFER_TRANSACTIONS_REQUEST,
  GET_TRANSFER_TRANSACTIONS_SUCCESS,
  GET_TRANSFER_TRANSACTIONS_ERROR
} from "../configurations/Types";

export const getTransferRequest = (params, cb, clearFlag, ofPage) => {
  return {
    type: GET_TRANSFER_TRANSACTIONS_REQUEST,
    params,
    callback: cb,
    clearFlag,
    ofPage
  };
};

export const getTransferSuccess = (transferData, clearFlag, ofPage) => {
  console.log("getTransferSuccess", transferData);
  return {
    type: GET_TRANSFER_TRANSACTIONS_SUCCESS,
    transferData,
    clearFlag,
    ofPage
  };
};

export const getTransferError = error => {
  return {
    type: GET_TRANSFER_TRANSACTIONS_ERROR,
    error
  };
};
