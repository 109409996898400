import { signUpShema } from "./signup";
import { addCardSchema } from "./addCard";
import { loginSchema } from "./login";
import { forgotPasswordSchema } from "./forgotPassword";
import { resetPasswordSchema } from "./resetPassword";
import { changePasswordSchema } from "./changePassword";
import { replyMessageSchema } from "./replyMessage";
import { contactSchema } from "./ContactForm";
import { changeLocationSchema } from "./changeLocation";
import { updateProfileSchema } from "./updateProfileSchema";
import { addBankSchema } from "./addBank";
import { split } from "./split";
import { deposit } from "./deposit";
import { recurringDeposit } from "./recurring/RecurringDeposit";
import { addContactSchema } from "./contact";
import { depositWithSplits } from "./depositWithSplits";
import { withdraw } from "./widthdraw";
import { withdrawWithSplits } from "./withdrawWithSplits";
import { recurringWithdrawal } from "./recurring/RecurringWithdrawal";
import { transfer } from "./transfer";
import { transferWithSplits } from "./transferWithSplits";
import { addAccountSchema } from "./AccountName";
import { jobSchema } from "./job";
import { SendReportSchema } from "./reports/SendReport";
import { BankAccountLedgerSchema } from "./reports/BankAccountLedger";
import { TrialBalanceSummarySchema } from "./reports/TrialBalanceSummary";
import { ProfitAndLossSchema } from "./reports/ProfitAndLoss";
import { TransferJournalLedgerSchema } from "./reports/TransferJournalLedger";
import { ReconciliationForLawyersSchema } from "./reports/ReconciliationForLawyers";
import { buySubscriptionSchema } from "./subscription/BuySubscription";

const signUpValidations = signUpShema;

const addCardValidations = addCardSchema;

const loginValidations = loginSchema;

const emailValidations = forgotPasswordSchema;

const resetPassword = resetPasswordSchema;

const changePassword = changePasswordSchema;

const replyMessage = replyMessageSchema;

const contactForm = contactSchema;

const changeLocation = changeLocationSchema;
const updateProfiles = updateProfileSchema;
const addBank = addBankSchema;
// const split = splitSchema
const addAccount = addAccountSchema;

export {
  signUpValidations,
  addCardValidations,
  loginValidations,
  emailValidations,
  resetPassword,
  changePassword,
  replyMessage,
  split,
  contactForm,
  changeLocation,
  updateProfiles,
  addBank,
  deposit,
  recurringDeposit,
  addAccount,
  addContactSchema,
  depositWithSplits,
  withdraw,
  withdrawWithSplits,
  recurringWithdrawal,
  transfer,
  transferWithSplits,
  jobSchema,
  SendReportSchema,
  BankAccountLedgerSchema,
  TrialBalanceSummarySchema,
  ProfitAndLossSchema,
  TransferJournalLedgerSchema,
  ReconciliationForLawyersSchema,
  buySubscriptionSchema
};
