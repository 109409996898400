import {
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_ERROR,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_ERROR,
} from "../../configurations/Types";

export const addTransactionRequest = (body, cb) => {
  return {
    type: ADD_TRANSACTION_REQUEST,
    body,
    cb
  };
};

export const addTransactionSuccess = () => {
  return {
    type: ADD_TRANSACTION_SUCCESS,
    
  };
};

export const addTransactionError = () => {
  return {
    type: ADD_TRANSACTION_ERROR
  };
};

//Edit transaction
export const updateTransactionRequest = (body, id, cb) => {
  return {
    type: UPDATE_TRANSACTION_REQUEST,
    body,
    id,
    cb
  };
};

export const updateTransactionSuccess = () => {
  return {
    type: UPDATE_TRANSACTION_SUCCESS,
    
  };
};

export const updateTransactionError = () => {
  return {
    type: UPDATE_TRANSACTION_ERROR
  };
};
