import {
  GET_TRANSFER_TRANSACTIONS_REQUEST,
  GET_TRANSFER_TRANSACTIONS_SUCCESS,
  GET_TRANSFER_TRANSACTIONS_ERROR
} from "../configurations/Types";

const INITIAL_STATE = {
  totalCount: 0,
  transferTransactions: [],
  transferLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRANSFER_TRANSACTIONS_REQUEST: {
      //console.log("GOT USER", action.payload)
      return Object.assign({}, state, { transferLoading: true });
    }

    case GET_TRANSFER_TRANSACTIONS_SUCCESS: {
      console.log("GOT USER", action);
      let transferHolder = [];
      console.log(
        Object.values(action.transferData.all_transactions),
        "Transfer Data"
      );
      transferHolder = Object.values(action.transferData.all_transactions);
      console.log("transfer Holder", transferHolder);
      console.log(
        Object.values(transferHolder.map(item => Object.values(item)).flat()),
        "my console"
      );
      transferHolder = Object.values(
        transferHolder.map(item => Object.values(item)).flat()
      );

      if (
        state.transferTransactions.length !== 0 &&
        !action.clearFlag &&
        !action.ofPage
      ) {
        transferHolder = [...state.transferTransactions, ...transferHolder];
        console.log("seted transferHolder array", transferHolder);
      }
      if (action.ofPage) {
        console.log("pahge ofSize", action.ofPage);
        let { page, pageSize } = action.ofPage;
        
        let newTransferTransactions = [...state.transferTransactions];
        console.log("new Transactions", newTransferTransactions);
        newTransferTransactions.splice(
          page * pageSize,
          newTransferTransactions.length - page * pageSize,
          ...transferHolder
        );
        transferHolder = newTransferTransactions;
        // console.log("newTransferTransactions", newTransferTransactions);
      }

      console.log("filer data", transferHolder);
      return Object.assign({}, state, {
        transferLoading: false,
        transferTransactions: transferHolder,
        totalCount: action.transferData.total_count
      });
    }

    case GET_TRANSFER_TRANSACTIONS_ERROR: {
      console.log("GOT USER", action.payload);
      // return Object.assign({}, state, { transferLoading: true });
    }
    default: {
      return state;
    }
  }
};
