import {
  RECORD_TRANSFER_REQUEST,
  RECORD_TRANSFER_SUCCESS,
  RECORD_TRANSFER_ERROR,
  UPDATE_TRANSFER_REQUEST,
  UPDATE_TRANSFER_SUCCESS,
  UPDATE_TRANSFER_ERROR
} from "../../configurations/Types";

export const recordTransferRequest = (body, cb) => {
  return {
    type: RECORD_TRANSFER_REQUEST,
    body,
    cb
  };
};

export const recordTransferSuccess = () => {
  return {
    type: RECORD_TRANSFER_SUCCESS,
    
  };
};

export const recordTransferError = () => {
  return {
    type: RECORD_TRANSFER_ERROR
  };
};

//Edit transfer
export const updateTransferRequest = (body, id, cb) => {
  return {
    type: UPDATE_TRANSFER_REQUEST,
    body,
    id,
    cb
  };
};

export const updateTransferSuccess = () => {
  return {
    type: UPDATE_TRANSFER_SUCCESS,
    
  };
};

export const updateTransferError = () => {
  return {
    type: UPDATE_TRANSFER_ERROR
  };
};