import {
  GET_PRINT_TRANSACTION_REQUEST,
  GET_PRINT_TRANSACTION_SUCCESS,
  GET_PRINT_TRANSACTION_ERROR,
  GET_CREATE_CHECK_REQUEST,
  GET_CREATE_CHECK_SUCCESS,
  GET_CREATE_CHECK_ERROR,
} from "../configurations/Types";

const INITIAL_STATE = {
  totalCount: 0,
  transactions: [],
  tableLoading: false,
  lastCheckNumber: "0001",
  printSummaryLoader: false,
  pdfUrl: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRINT_TRANSACTION_REQUEST: {
      //console.log("GOT USER", action.payload)
      return Object.assign({}, state, { tableLoading: true });
    }

    case GET_PRINT_TRANSACTION_SUCCESS: {
      let arr = action.payload.all_transactions;
      let allTransactions = [];
      console.log("arr", arr);
      console.log("clearFlag", action.clearFlag);
      let transactionHolder = [];
      if (action.payload) {
        transactionHolder = Object.values(arr);

        arr = allTransactions.concat(...transactionHolder);
        console.log("all ytr arr", arr);
      }
      console.log("CHeck flag Success:", action, arr);
      if (
        state.transactions.length !== 0 &&
        !action.clearFlag &&
        !action.ofPage
      ) {
        arr = [...state.transactions, ...arr];
        console.log("seted array", arr);
      }
      if (action.ofPage) {
        console.log("pahge ofSize", action.ofPage);
        let { page, pageSize } = action.ofPage;
        let newTransactions = [...state.transactions];
        console.log("new Transactions", newTransactions);
        newTransactions.splice(
          page * pageSize,
          newTransactions.length - page * pageSize,
          ...arr
        );
        arr = newTransactions;
        // console.log("newTransactions", newTransactions);
      }
      console.log("state.transactions", arr);
      return Object.assign({}, state, {
        totalCount: parseInt(action.payload.total_count),
        lastCheckNumber: action.payload.last_check_number
          ? action.payload.last_check_number
          : state.lastCheckNumber,
        transactions: arr,
      });
    }

    case GET_PRINT_TRANSACTION_ERROR: {
      console.log("Error");
      return Object.assign({}, state, { totalCount: 0, transactions: [] });
    }

    case GET_CREATE_CHECK_REQUEST: {
      return Object.assign({}, state, { printSummaryLoader: true });
    }

    case GET_CREATE_CHECK_SUCCESS: {
      return Object.assign({}, state, {
        printSummaryLoader: false,
        pdfUrl: action.pdfUrl,
      });
    }

    case GET_CREATE_CHECK_ERROR: {
      return Object.assign({}, state, { printSummaryLoader: false });
    }

    default:
      return state;
  }
};
